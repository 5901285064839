<script lang="ts">
    import {applicationContextKey} from 'PublicationDependencies';
    import {Column, Title} from 'Components/UI';
    import translations from 'Assets/i18n';
    import {getContext} from 'svelte';
    import DependencyContainer from 'Lib/DependencyContainer';
    import UserService from 'Lib/Services/UserService';
    import {getLoginComponentFor} from 'Pages/LoginPage/LoginProviderCompomponents';

    const applicationContext = getContext<DependencyContainer>(applicationContextKey);
    const userService = applicationContext.get<UserService>(UserService);
    const translationContext = translations.pages.login;

    let availableLoginProvidersPromise = userService.availableProviders;
</script>
<Column verticalAlign="center" horizontalAlign="center">
    <Column verticalAlign="begin" horizontalAlign="justify">
        <Title padding="16px" size="2em">{translationContext.title}</Title>
        {#await availableLoginProvidersPromise}
            {translationContext.fetchingProviders}
        {:then availableLoginProvidersMap}
            {#each availableLoginProvidersMap.keys() as key}
                <svelte:component this={getLoginComponentFor(key)}/>
            {/each}
        {:catch error}
            {translationContext.loginFailed}
        {/await}
    </Column>
</Column>
